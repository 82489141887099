import React, { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Modal, Button } from "rsuite";
import Axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import logo from "../Assets/Images/logo.png";
import { ApiEndPoint, ApiToken } from "../Data/Api";
import { QRCodeCanvas } from "qrcode.react";
// import header from '../../Images/header.png'
// import footer from '../../Images/footer.png'
import Loader from "../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import CancellBooking from "./CancellationBanktransfer";

function HotelInvoice() {
  const { id } = useParams();

  var endpoint = ApiEndPoint();
  const todayDate = new Date();
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [hotelDetail, setHotelDetail] = useState([]);
  const [agentDetail, setAgentDetail] = useState({});
  const [invoiceNo, setInvoiceNo] = useState("");
  const [hcnNumber, setHcnNumber] = useState('');
  const [brnNumber, setBrnNumber] = useState('');
  const [hotel, setHotel] = useState([]);
  const [CheckoutRes, setCheckoutRes] = useState('');
  const [mealList, setMealList] = useState([]);
  const [hoteldetail2, setHoteldetail2] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leadPassenger, setLeadPassenger] = useState([]);
  const [otherPassenger, setOtherPassenger] = useState([]);
  const [roomPriceSums, setRoomPriceSums] = useState([]);
  const baseUrl = "https://alsubaee.com/hotel_invoice/";
  const finalRoomPrices = [];

  useEffect(() => {
    getinvoicedetail();
  }, []);

  useEffect(() => {
    total();
  }, [rooms]);
  let Checkin = moment(hoteldetail2.checkIn);
  let checkout = moment(hoteldetail2.checkOut);
  let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
  const getinvoicedetail = async () => {
    setIsLoading(true);
    var invoiceno = {
      // invoice_no: 'AHT3250517'
      invoice_no: id,
    };
    setInvoiceNumber(id);
    // _Live
    try {
      const response = await Axios.post(
        endpoint + "/api/hotels/view/reservation_Live",
        invoiceno,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      if (response.length !== "") {
        setIsLoading(false);
        // console.log(response)
        var data = response.data.booking_data;
        var reservationrequest = JSON.parse(data.reservation_request);
        if(response.data.booking_data.status ==="Tentative" && response.data.booking_data.payment_Type==="Bank_Payment"){
          const createdAt = moment(response.data.booking_data.created_at);
          const now = moment();
          var payemnt_Details=JSON.parse(response.data.booking_data.payment_details);
      
          if (now.isAfter(createdAt.add(24, 'hours'))  && payemnt_Details==='') {
              var cancellRes= await CancellBooking(data.invoice_no);
              if(cancellRes !== 'No'){
                data=cancellRes;
                response.data.booking_data=cancellRes;
              }
              // Add your logic here
          } 
        }
        setAgentDetail(response.data?.b2b_Agent)
        setHcnNumber(data?.hcn_Number);
        setBrnNumber(data?.brn_Number)
        setInvoiceNo(data.invoice_no);
        var lead = JSON.parse(data.lead_passenger_data);
        var other = JSON.parse(data.other_adults_data);
        setCheckoutRes(reservationrequest);
        if(data?.meal_Details){
          var mealdata = JSON.parse(data.meal_Details);
          setMealList(mealdata);
        }
        setOtherPassenger(other);
        setLeadPassenger(lead);
        var hoteldata = JSON.parse(data.reservation_response);
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        );
        setHoteldetail2(hoteldata.hotel_details);
        setRooms(hoteldata.hotel_details.rooms);
        setHotel(hoteldata);
        setInvoiceDetail(response.data.booking_data);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const printinvoice = () => {
    window.print();
  };
  const total = () => {
    let roomsprice = 0;
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = 0;
    var length = rooms.length;
    for (var x = 0; x < length; x++) {
      roomsprice = Number(rooms[x].room_rates[0].net);
      finalpricemarkup = roomsprice;
      if (Number(hotel.admin_markup) !== 0) {
        if (hotel.admin_markup_type === "Percentage") {
          markupprice = (roomsprice * Number(hotel.admin_markup)) / 100;
        } else {
          markupprice = Number(hotel.admin_markup);
        }
        // adminmarkupprice=markupprice;
        finalpricemarkup += markupprice;
      }
      if (Number(hotel.customer_markup) !== 0) {
        if (hotel.customer_markup_type === "Percentage") {
          markupprice =
            (finalpricemarkup * Number(hotel.customer_markup)) / 100;
        } else {
          markupprice = Number(hotel.customer_markup);
        }
        //  clientmarkupprice=markupprice;
        finalpricemarkup += markupprice;
      }
      finalRoomPrices.push(finalpricemarkup);
      setRoomPriceSums(finalRoomPrices);
    }
  };
  const calculatemarkup = (price) => {
    let roomsprice = Number(price);
    let markupprice = 0;
    let finalpricemarkup = Number(price);

    if (Number(hotel.admin_markup) !== 0) {
      if (hotel.admin_markup_type === "Percentage") {
        markupprice = (roomsprice * Number(hotel.admin_markup)) / 100;
      } else {
        markupprice = Number(hotel.admin_markup);
      }
      // adminmarkupprice=markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(hotel.customer_markup) !== 0) {
      if (hotel.customer_markup_type === "Percentage") {
        markupprice = (finalpricemarkup * Number(hotel.customer_markup)) / 100;
      } else {
        markupprice = Number(hotel.customer_markup);
      }
      //  clientmarkupprice=markupprice;
      finalpricemarkup += markupprice;
    }
    return finalpricemarkup.toFixed(2);
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const BookingCancillation = async () => {
    var apitoken = ApiToken();
    setModalOpen(!modalOpen);
    var data = {
      token: apitoken,
      invoice_no: invoiceNo,
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/hotel/reservation/cancell/new_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      var data = JSON.parse(response.data.view_reservation_details);
      if (data.error) {
        setIsLoading(false);
        toast.info(data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        getinvoicedetail();
        toast.info("Your reservation has been cancelled", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const fullInvoiceUrl = `${baseUrl}${invoiceNumber}`;
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Modal open={modalOpen} onClose={toggleModal}>
        <Modal.Header>
          <Modal.Title> Confirmation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={BookingCancillation} appearance="danger">
            Confirm
          </Button>
          <Button onClick={toggleModal} appearance="subtle">
            close
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ background: "#80808017" }}>
        <div className="cs-container">
          <div className="cs-invoice cs-style1">
            <div className="cs-invoice_in">
              <div style={{ color: "#000" }}>
                <div class="row mb-4">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <img src={logo} height="90" width="70" />
                      <div className="ms-2 invoice-text-theme">
                        <h4 className="text-info fw-bold">Alsubaee Holidays</h4>
                        <p className="fw-bold">For Travels</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 invoice-address d-flex flex-column mobile-text-start align-items-end justify-content-center ">
                    <p className="mt-0">
                      <FontAwesomeIcon
                        className="text-success"
                        icon={faMapMarkerAlt}
                      />{" "}
                      Office #46, Sultana Center, Sultana street madina 41411
                    </p>
                    <p className="mt-0">POBOX 905, 41421</p>
                    <p className="mt-0">
                      <FontAwesomeIcon
                        className="text-success"
                        icon={faEnvelope}
                      />{" "}
                      reservation@alsubaeeholidays.com
                    </p>
                    <p className="mt-0">VAT#: 301175961500003</p>
                  </div>
                  {/* <div className="col-lg-8 col-md-7 col-sm-7 mt-4">
                    {invoiceDetail.status === "CONFIRMED" ? (
                      <h6 class="mb-3">
                        Invoice No: {invoiceNo} | Booking Status:{" "}
                        <span style={{ color: "#63ab45" }}>
                          {invoiceDetail.status}
                        </span><br/>
                        <span >
                          HCN No: {hcnNumber} 
                        </span><br/>
                        <span >
                        Al Subaee Res No: {brnNumber}
                        </span>

                      </h6>
                    ) : (
                      <h6 class="mb-3">
                        Invoice No: {invoiceNo} | Status:{" "}
                        {invoiceDetail.status === "In_Progess"
                          ? "In Progress"
                          : invoiceDetail.status}
                          <br/>
                        <span >
                          HCN No: {hcnNumber} 
                        </span><br/>
                        <span >
                        Al Subaee Res No: {brnNumber}
                        </span>
                      </h6>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-5 col-sm-5 mobile-text-start text-end mt-4">
                    <h6>
                      DATE CREATED:{" "}
                      {moment(invoiceDetail.creationDate).format("DD-MM-YYYY")}
                    </h6>
                  </div> */}
                </div>
                <div className="row">
                    <div  className="col-md-6 mt-2">
                    {invoiceDetail.status === "CONFIRMED" ? (
                      <h6 class="mt-2 ">
                        Invoice No: {invoiceNo} | Booking Status:{" "}
                        <span style={{ color: "#63ab45" }}>
                          {invoiceDetail.status}
                        </span><br/>
                        <span >
                          HCN No: {hcnNumber} 
                        </span><br/>
                        <span >
                        Al Subaee Res No: {brnNumber}
                        </span>

                      </h6>
                    ) : (
                      <h6 class="mt-2 ">
                        Invoice No: {invoiceNo} | Status:{" "}
                        {invoiceDetail.status === "In_Progess"
                          ? "In Progress"
                          : invoiceDetail.status}
                          <br/>
                        <span >
                          HCN No: {hcnNumber} 
                        </span><br/>
                        <span >
                        Al Subaee Res No: {brnNumber}
                        </span>
                      </h6>
                    )}
                    </div>
                    <div  className="col-md-6 mt-2">
                    <h6 className="mt-2 ">
                      DATE CREATED:{" "}
                      {moment(invoiceDetail.creationDate).format("DD-MM-YYYY")}
                    </h6>
                      <h6 className="mt-2 ">Invoice To : {agentDetail?.company_name}</h6>
                    </div>
                </div>
                <div className="row mt-2 hotel-invoice_guest">
                  <div className="col-md-6">
                    <ul class="list-group list-group-borderless">
                      <li class=" d-flex gap-2 align-items-center my-1">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Guest Name:
                        </span>
                        <span class="fw-normal mb-0">
                          {leadPassenger.lead_title +
                            " " +
                            leadPassenger.lead_first_name +
                            " " +
                            leadPassenger.lead_last_name}
                        </span>
                      </li>
                      <li class=" d-flex gap-2 align-items-center my-1">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Nationality:
                        </span>
                        <span class="fw-normal mb-0">
                          {leadPassenger.lead_country}
                        </span>
                      </li>
                      <li class=" d-flex gap-2 align-items-center my-1">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Mobile:
                        </span>
                        <span class="fw-normal mb-0">
                          {leadPassenger.lead_phone}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div  className="col-md-6 text-center">
                    {/* <QRCodeCanvas
                      value={fullInvoiceUrl}
                      size={120}
                      level={"H"}
                    /> */}
                     <ul class="list-group list-group-borderless">
                     {invoiceDetail.payment_Reject_Status !==null && (
                      <li class=" d-sm-flex text-danger fw-bold justify-content-between align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Payment Status:
                        </span>
                        <span class=" mb-0">
                          {invoiceDetail.payment_Reject_Status}
                        </span>
                      </li>
                      )}
                      <li class=" d-flex gap-2 align-items-center my-1">
                        <span class="mb-0">
                        <i class="bi bi-vr fa-fw me-2"></i>Check in :
                        </span>
                        <span class="fw-normal mb-0">
                         {moment(hoteldetail2.checkIn).format("ll")}
                        </span>
                      </li>
                      <li class=" d-flex gap-2 align-items-center my-1">
                        <span class="mb-0">
                        <i class="bi bi-vr fa-fw me-2"></i>Check out :
                        </span>
                        <span class="fw-normal mb-0">
                         {moment(hoteldetail2.checkOut).format("ll")}
                        </span>
                      </li>
                      {CheckoutRes.slc_pyment_method === 'Bank_Payment'&& invoiceDetail.status !== "Confirmed" && (
                          <li style={{fontFamily:'system-ui'}} class="d-sm-flex gap-2 align-items-center">
                            <span class="mb-0 fw-bold">
                              <i class="bi bi-calendar fa-fw me-2"></i>Option Date :
                            </span>
                            {moment(invoiceDetail.creationDate).add(1, 'days').isAfter(moment()) ? (
                              <span class="fw-bold mb-0">
                                {moment(invoiceDetail.creationDate).add(1, 'days').format("ll")}
                              </span>
                            ) : (
                              <span class="text-danger fw-bold mb-0">
                               {moment(invoiceDetail.creationDate).add(1, 'days').format("ll")} (past due)
                              </span>
                            )}
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
                <div class="accommodation-details mt-3">
                  <h6>HOTEL DETAILS</h6>
                  <div class="mobile-table-scroll ">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>City</th>
                          <th>Hotel</th>
                          <th>Category</th>
                          <th>Price:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> {hoteldetail2.destinationName}</td>
                          <td>{hoteldetail2.hotel_name}</td>
                          <td>{hoteldetail2.stars_rating} Stars</td>
                          <td>
                            {invoiceDetail.selected_currency}{" "}
                            {invoiceDetail.status == "Cancelled"
                              ? invoiceDetail.after_Confirm_Status==='Cancelled'? Number(invoiceDetail.exchange_price):0.0
                              : invoiceDetail.exchange_price}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="accommodation-details mt-3">
                  <h6>ROOM DETAILS</h6>
                  <div class="mobile-table-scroll ">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Name</th>
                          <th>Code</th>
                          <th>Board Type</th>
                          <th>Room Quantity</th>
                          <th>Passenger</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rooms.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td> {item.room_name}</td>
                            <td>{item.room_code}</td>
                            <td> {item.room_rates[0].room_board}</td>
                            <td>{item.room_rates[0].room_qty}</td>
                            <td>
                              {" "}
                              {item.room_rates[0].adults} {Number(item.room_rates[0].adults)===1?'Adult':'Adults'},{" "}
                            {item.room_rates[0].children} {Number(item.room_rates[0].children)===1?'Children':'Childrens'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {mealList.length !==0 &&(
                <div class="accommodation-details mt-3">
                  <h6>MEAL DETAILS</h6>
                  <div class="mobile-table-scroll ">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Name</th>
                          <th>Adult</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mealList.map((item, index) => (
                          <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {item.name}</td>
                          <td>{item.quantity}</td>
                          <td>{invoiceDetail.selected_currency} {(Number(item.totalPrice)*Number(CheckoutRes.exchange_rate)).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                )}
                <div className="mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="my-2">Bank Accounts:</h6>
                      <p>Bank Name: SAUDI NATIONAL BANK SNB</p>
                      <p>Account Name: ABDULLAH ALSUBAEE FOR HOLIDAYS</p>
                      <p>Account No.: 32672680000105</p>
                      <p>Iban No.: SA501000032672680000105</p>
                      <p>Swift No.: NCBKSAJE</p>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-between">
                      <table class="table table-bordered">
                        <thead></thead>
                        <tbody>
                          <tr>
                            <td>Total</td>
                            <td>
                              <span className={`${invoiceDetail.after_Confirm_Status==='Cancelled'? 'text-danger':''}`}>
                              {invoiceDetail.status == "Cancelled"
                                ? invoiceDetail.after_Confirm_Status==='Cancelled'? 'Cancellation Charges '+invoiceDetail.selected_currency+' '+ Number(invoiceDetail.cancellation_Price_CR):invoiceDetail.selected_currency+' '+0.0
                                : invoiceDetail.selected_currency+' '+invoiceDetail.exchange_price}
                              </span><br />
                              <small>vat and taxes included</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        All rates are inclusive of 15% VAT and 5% Municipality
                        Tax.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-30 vouvher-terms">
                  <h5 className="p-3">Terms & Conditions</h5>
                  <div className="p-4 ms-4">
                    <ul style={{ listStyle: "disc" }}>
                      <li>
                        Above Rates are net & non commission-able quoted in
                        Saudi Riyals
                      </li>
                      <li>
                        Check in after 16:00 hours and check out at 12:00 hour
                      </li>
                      <li>
                        Check in or check out amendment for individuals should
                        be done 72 hours prior to guest check in.
                      </li>
                      <li>
                        Check in or check out amendment for Group should be 7
                        days prior to guest check in.
                      </li>
                      <li>
                        In case of no-show for groups full amount will be
                        charged.
                      </li>
                      <li>
                        In case of no-show for individuals first night will be
                        charged.
                      </li>
                      <li>
                        Triple or Quad occupancy will be through extra bed, If
                        standard room is not available.
                      </li>
                      <li>
                        For Waqf Osman Bin Affan Hotel bookings – check in
                        through ALSUBAEE representative.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div class="accommodation-details mt-3">
          <h6>PASSENGERS' INFORMATION</h6>
          <div class="mobile-table-scroll ">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Pilgrims Name</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lead Passenger</td>
                  <td>
                    {leadPassenger.lead_title +
                      " " +
                      leadPassenger.lead_first_name +
                      " " +
                      leadPassenger.lead_last_name}
                  </td>
                  <td>{leadPassenger.lead_email}</td>
                  <td>
                    {moment(leadPassenger.lead_date_of_birth).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  <td>{leadPassenger.lead_country} </td>
                </tr>
                {otherPassenger.length > 0 &&
                  otherPassenger.map((item, index) => (
                    <tr key={index}>
                      <td>Passenger {index + 2}</td>
                      <td>{item.title + " " + item.name}</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>{item.nationality}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div> */}
                <div className="text-center mt-3 print-div-hide justify-content-center d-flex  ms-2">
                  <button
                    onClick={printinvoice}
                    className="btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success"
                  >
                    Print Invoice
                  </button>
                  <NavLink to="/">
                    <button class="btn cursor-pointer ms-2 btn-info mb-0">
                      <FontAwesomeIcon icon={faHome} /> Home
                    </button>
                  </NavLink>
                </div>
                {/* <div className='row'>
        <div class=' col-lg-12 mt-3'>
          <p>MAKKAH OFFICE | SOHAIL NAWAZ SADOZAI | PHONE #: 058-3390811</p>
          <p>MAKKAH OFFICE | OWAIS BHUTTA | PHONE #: 059-8382599</p>
          <p>MADINA OFFICE | KOUSAR NADEEM | PHONE #: 059-1139698</p>
          <p>MADINA OFFICE | RIZWAN MED TRANSPORT | PHONE #: 0599104921</p>
          <p>
            JEDDAH AIRPORT OFFICE | TRANSPORT BY NAQAL ARABI AND WAFA AL MASSI |
            PHONE #: 05
          </p>
        </div>
        <div class=' text-end col-lg-6 mt-3'>
          <h6>:نوٹ</h6>
          <p> براہ کرم اس بات کا خاص خیال رکھیں کہ روانگی سے کم از کم تین گھنٹے پہلے ائرپورٹ پہنچیں تاکہ آپ کی روانگی کے وقت آپ کو کسی قسم کی پریشانی کا سامنا نہ کرنا پڑے۔ علاوہ ازیں، تمام سفراء کو اطلاع دی جاتی ہے کہ وہ اپنے سفری دستاویزات اور شناختی کارڈز کو ہمراہ رکھیں۔</p>
        </div>
        </div> */}
                {/* <img src={footer} width='100%'/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HotelInvoice;

import React from "react";
import { ApiEndPoint,ApiToken } from "../Data/Api";
import axios from "axios";


const CancellBooking=async(invoiceno)=>{
    var baseurl=ApiEndPoint();
    var token=ApiToken();

    var data={
        token:token,
        invoice_no:invoiceno
    }
    // alert('Hello');
    try{
        const response =await axios.post(baseurl+'/api/bank_Transfer_Cancel',data);
        if(response.data.status==='success'){
            return  response.data.booking_data;
        }else{
            return 'No'
        }

    }catch(error){
        console.log(error);
        return 'No'
    }


}

export default CancellBooking;
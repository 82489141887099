import React, { useEffect, useState } from "react";
import { ApiEndPoint, ApiToken } from "../../Data/Api";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, Button } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../../reducers/hotelActions";
import { NavLink } from "react-router-dom";
function Statement({ userData }) {
  // console.log(userData);
  const dispatch=useDispatch();
  const [creditList, setCreditList] = useState([]);
  const [creditLimitAdd, setCreditLimitAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestCredit, setRequestCredit] = useState(false);
  const [overflow, setOverflow] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");
  const [customerData, setCustomerData] = useState({});
  const [CustomerAmount, setCustomerAmount] = useState({
    total: 0,
    remeaning: 0,
  });
  const [errors, setErrors] = useState({});
  const token = ApiToken();
  const baseurl = ApiEndPoint();
  const [formData, setFormData] = useState({
    trid: "",
    trremark: "",
    amount: "",
  });
  //   const customStyles = {
  //     headCells: {
  //       style: {
  //         fontWeight: "bold",
  //         backgroundColor: "#46a8a7",
  //         color: "#ffff",
  //       },
  //     },
  //     rows: {
  //       style: {
  //         backgroundColor: "#f7f7fa",
  //       },
  //     },
  //   };

  useEffect(() => {
    DepositCreditList();
  }, []);
  const DepositCreditList = async () => {
    var data = {
      token: token,
      b2b_Agent_Id: userData.id,
    };

    try {
      const response = await axios.post(
        baseurl + "/api/b2b_View_Credit_Limit",
        data
      );
      // console.log(response);
      if (response.data.status === "success") {
        if (response.data?.b2b_Agent_Credit_Limits) {
          setCreditLimitAdd(true);
        } else {
          setCreditLimitAdd(false);
        }
        const creditList=response.data?.b2b_Agent_Credit_Limits?.map((item, index) => ({
          ...item,
          count_no: index + 1, // Start count from 1
        }));
        setCustomerData(response.data.customer_Data);
        setCreditList(creditList);
        setCustomerAmount({
          total: response.data.total_Amount,
          remeaning: response.data.remaining_Amount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const columns1 = [
  //     {
  //       name: "Sr",
  //       selector: (row, index) => index + 1,
  //       sortable: true,
  //       cell: (row, index) => index + 1,
  //       width: "100px",
  //     },
  //     {
  //       name: "Transaction Id",
  //       selector: (row) => `${row.transaction_Id}`,
  //       sortable: true,
  //     },
  //     {
  //       name: "Payment Method",
  //       selector: (row) => `INV${row.payment_Method}`,
  //       sortable: true,
  //     },
  //     {
  //       name: "Paid Amount",
  //       selector: (row) => `${row.currency} ${row.booking_Amount}`,
  //       sortable: true,
  //     },
  //     {
  //       name: "Payment Status",
  //       selector: (row) => `${row.status_Type}`,
  //       sortable: true,
  //     },
  //   ];
  const columns = [
    {
      field: "count_no",
      headerName: "Sr",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "transaction_Id",
    //   headerName: "Transaction Id",
    //   width: 170,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     let text;
    //     if (params.row.payment_Method === "Cash")
    //       text = params.row.payment_Remarks;
    //     else text = params.row.transaction_Id;

    //     return <div>{text}</div>;
    //   },
    // },
    // {
    //   field: "payment_Method",
    //   headerName: "Payment Method",
    //   width: 165,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "booking_Amount",
      renderCell: (params) =>
        `${params.row.currency} ${params.row.booking_Amount}`,
      headerName: "Paid Amount",
      width: 290,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_at",
      renderCell: (params) => ` ${moment(params.row.created_at).format("ll")}`,
      headerName: "Date",
      width: 290,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status_Type",
      headerName: "Payment Status",
      width: 290,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let bgColor;
        if (params.value === "Approved") bgColor = "green";
        else if (params.value === "Pending") bgColor = "orange";
        else if (params.value === "Rejected") bgColor = "red";

        return (
          <div
            style={{
              color: bgColor,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setIsSubmit(false);
  };
  const handlePaymentMethodChamge = (event) => {
    setPaymentMethod(event.target.value);
  };
  const handleformchange = (event) => {
    const { name, value } = event.target;
    setFormData((prevformdata) => ({ ...prevformdata, [name]: value }));
  };

  const validation = () => {
    let newErrors = {};
    if (paymentMethod === "Bank Transfer") {
      if (!formData.trid) {
        newErrors.trid = "Please Enter Transaction Id.";
      } else if (!/^\+?\d{5,25}$/.test(formData.trid)) {
        newErrors.trid = "Transaction Id must be between 5 and 25 digits.";
      }
    } else {
      if (!formData.trremark) {
        newErrors.trremark = "Please Write Transaction Remarks.";
      }
    }
    if (!formData.amount) {
      newErrors.amount = "Please Enter Add Amount.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const SubmitNewCredit = async () => {
    if (validation()) {
      return;
    }
    setIsSubmit(true);
    const data = {
      token: token,
      b2b_Agent_Id: userData.id,
      transaction_Id: formData.trid,
      booking_Amount: formData.amount,
      total_Amount: CustomerAmount.total,
      currency: customerData.currency_symbol,
      payment_Method: paymentMethod,
      payment_Remarks: formData.trremark,
      remaining_Amount: CustomerAmount.remeaning,
      services_Type: "Hotel",
      status: "Credit_Payment",
    };

    try {
      const response = await axios.post(
        baseurl + "/api/b2b_Add_Credit_Limit",
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        DepositCreditList();
        setIsSubmit(false);
        setOpen(false);
        setFormData({
          trid: "",
          trremark: "",
          amount: "",
        });
      }
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };
  const SubmitCreditRequest = async () => {
    setIsSubmit(true);
    const data = {
      token: token,
      id: userData.id,
    };

    try {
      const response = await axios.post(
        baseurl + "/api/request_B2B_Agents_Credit_Limit",
        data
      );
      console.log(response);
      if (response.data.status === "success") {
        userData.request_Credit_Limit='Yes';
        dispatch(setLoginUser(userData));
        toast.success(response.data.message);
        setIsSubmit(false);
        setRequestCredit(false);
      } else {
        toast.error(response.data.message);
        setIsSubmit(false);
        setRequestCredit(false);
      }
    } catch (error) {
      setIsSubmit(false);
      console.log(error);
    }
  };
  return (
    <>
      <ToastContainer />
      <section class="pt-0">
        <div class="container vstack gap-4">
          <div class="row">
            <div class="col-12">
              <h4 class="fs-4 mb-0">
                <i class="bi bi-house-door fa-fw me-1">
                  <FontAwesomeIcon icon={faDollar} />
                </i>
                Statement
              </h4>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="agent-head">
                <h5>
                  Total Paid: {customerData?.currency_symbol}{" "}
                  {CustomerAmount.total}
                </h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="agent-head">
                <h5>
                  Available Balance: {customerData?.currency_symbol}{" "}
                  {CustomerAmount.remeaning}
                </h5>
              </div>
            </div>
          </div>

          <div className="mt-2 text-end">
            {userData?.allow_Credit_Limit === "Yes" && userData?.request_Credit_Limit !=='Yes' ? (
               <button
               onClick={() => setRequestCredit(true)}
               className="btn btn-primary"
             >
               Request Credit limit
             </button>
              
            ) : (
              <>
              {(userData?.request_Credit_Limit &&
              userData?.request_Credit_Limit === "Yes" &&
              creditLimitAdd) ? (
                // <button
                //   className="btn btn-primary"
                // >
                //   Increase Credit limit
                // </button>
                <div></div>
              ) : (
                <h6 className="text-warning">Waiting for approval</h6>
              )}
            </>
            )}

            {/* <NavLink to='/dashboard/income_ledger'>
          <button  className="btn btn-success me-2">
              Bookings
            </button>
            </NavLink> */}
            {/* <button onClick={() => setOpen(true)} className="btn btn-primary">
              Add Payment
            </button> */}
          </div>
          <DataGrid
            className="mt-4"
            rows={creditList}
            columns={columns}
            pageSize={5}
          />
          {/* <div id="dataTable">
            <DataTable
              columns={columns1}
              data={creditList}
              pagination
              subHeader
              persistTableHead
              customStyles={customStyles}
            />
          </div> */}
        </div>
      </section>
      <Modal
        backdrop="static"
        overflow={overflow}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Add Credit Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="form-group ">
              <h6>Pay To</h6>
              <input
                type="text"
                value={customerData.company_name}
                disabled
                className="form-control mt-2"
              />
            </div>
            <div className="form-group mt-4">
              <h6>Payment Method</h6>
              <select
                value={paymentMethod}
                onChange={handlePaymentMethodChamge}
                class="form-select mt-2"
                aria-label="Default select example"
              >
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cash">Cash</option>
              </select>
            </div>
            {paymentMethod === "Bank Transfer" ? (
              <div className="form-group mt-4">
                <h6>Transaction ID</h6>
                <input
                  type="text"
                  value={formData.trid}
                  name="trid"
                  onChange={handleformchange}
                  className="form-control mt-2"
                />
                {errors.trid && (
                  <span className="text-danger">{errors.trid}</span>
                )}
              </div>
            ) : (
              <div className="form-group mt-4">
                <h6>Transaction Remarks</h6>
                <input
                  type="text"
                  value={formData.trremark}
                  name="trremark"
                  onChange={handleformchange}
                  className="form-control mt-2"
                />
                {errors.trremark && (
                  <span className="text-danger">{errors.trremark}</span>
                )}
              </div>
            )}

            <div className="form-group mt-4">
              <h6>Total Amount</h6>
              <div class="input-group mb-3 add-vredit-group">
                <span class="input-group-text" id="basic-addon1">
                  {customerData.currency_symbol}
                </span>
                <input
                  type="text"
                  value={CustomerAmount.total}
                  disabled
                  className="form-control mt-2"
                />
              </div>
            </div>
            <div className="form-group mt-4">
              <h6>Available Amount</h6>
              <div class="input-group mb-3 add-vredit-group">
                <span class="input-group-text" id="basic-addon1">
                  {customerData.currency_symbol}
                </span>
                <input
                  type="text"
                  value={CustomerAmount.remeaning}
                  disabled
                  className="form-control mt-2"
                />
              </div>
            </div>
            <div className="form-group mt-4">
              <h6>Add Amount</h6>
              <div class="input-group mb-3 add-vredit-group">
                <span class="input-group-text" id="basic-addon1">
                  {customerData.currency_symbol}
                </span>
                <input
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formData.amount}
                  name="amount"
                  onChange={handleformchange}
                  className="form-control mt-2"
                />
              </div>
              {errors.amount && (
                <span className="text-danger">{errors.amount}</span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmit ? (
            <Button appearance="primary" loading>
              Primary
            </Button>
          ) : (
            <Button onClick={SubmitNewCredit} appearance="primary">
              Submit
            </Button>
          )}
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        open={requestCredit}
        onClose={() => setRequestCredit(false)}
      >
        <Modal.Header>
          <Modal.Title>Request Credit Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h6>
              Are you sure you want to submit your Request Credit Limit? Please
              confirm to proceed.
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmit ? (
            <Button appearance="primary" loading>
              Primary
            </Button>
          ) : (
            <Button onClick={SubmitCreditRequest} appearance="primary">
              Confirm
            </Button>
          )}

          <Button onClick={() => setRequestCredit(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Statement;

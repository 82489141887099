import React, { useEffect, useState } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { Modal, Button } from "rsuite";
import Axios from "axios";
import moment from "moment";
// import header from'../../Images/header.png'
// import footer from'../../Images/footer.png'
import logo from "../Assets/Images/logo.png";
import Loader from "../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { ApiEndPoint, ApiToken, StripeClientSecret } from "../Data/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { Stripe } from "stripe";
import CancellBooking from "./CancellationBanktransfer";
function HotelVoucher() {
  const { id } = useParams();
  var endpoint = ApiEndPoint();
  const todayDate = new Date();
  const navigation = useNavigate();
  var Secretkey = StripeClientSecret();
  const stripe = new Stripe(Secretkey);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [hotelDetail, setHotelDetail] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [CheckoutRes, setCheckoutRes] = useState('');
  const [hcnNumber, setHcnNumber] = useState('');
  const [brnNumber, setBrnNumber] = useState('');
  const [hotel, setHotel] = useState([]);
  const [hoteldetail2, setHoteldetail2] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [HotelMeta, setHotelMeta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancellationbtn, setShowCancellationbtn] = useState(true);
  const [leadPassenger, setLeadPassenger] = useState([]);
  const [paymentObject, setPaymentObjec] = useState([]);
  const [roomPriceSums, setRoomPriceSums] = useState([]);
  const finalRoomPrices = [];
  const [refundAmount, setRefundAmount] = useState(0);
  useEffect(() => {
    getinvoicedetail();
  }, []);
  useEffect(() => {
    total();
  }, [rooms]);
  let Checkin = moment(hoteldetail2.checkIn);
  let checkout = moment(hoteldetail2.checkOut);
  let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
  const getinvoicedetail = async () => {
    setIsLoading(true);
    var invoiceno = {
      invoice_no: id,
    };
    // _Live
    try {
      const response = await Axios.post(
        endpoint + "/api/hotels/view/reservation_Live",
        invoiceno,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      if (response.length !== "") {
        var data = response.data.booking_data;
        var reservationrequest = JSON.parse(data.reservation_request);
        if(response.data.booking_data.status ==="Tentative" && response.data.booking_data.payment_Type==="Bank_Payment"){
          const createdAt = moment(response.data.booking_data.created_at);
          const now = moment();
          var payemnt_Details=JSON.parse(response.data.booking_data.payment_details);
      
          if (now.isAfter(createdAt.add(24, 'hours'))  && payemnt_Details==='') {
              var cancellRes= await CancellBooking(data.invoice_no);
              if(cancellRes !== 'No'){
                data=cancellRes;
                response.data.booking_data=cancellRes;
              }
              // Add your logic here
          } 
        }
        setHcnNumber(data?.hcn_Number);
        setBrnNumber(data?.brn_Number)
        setInvoiceNo(data.invoice_no);
        var lead = JSON.parse(data.lead_passenger_data);
        setLeadPassenger(lead);
        var hoteldata = JSON.parse(data.reservation_response);
        setCheckoutRes(reservationrequest);
        if(data?.meal_Details){
        var mealdata = JSON.parse(data.meal_Details);
        setMealList(mealdata);
      }
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        );
        if (data.payment_details !== null) {
          if (JSON.parse(data.payment_details) !== "") {
            setPaymentObjec(JSON.parse(data.payment_details));
          }
        }
        setHoteldetail2(hoteldata.hotel_details);
        calculateRefund(hoteldata.hotel_details, hoteldata.total_price,Number(data.selected_exchange_rate));
        const shouldShowCancelButton = hoteldata.hotel_details.rooms.some(
          (room) =>
            room.room_rates.some((rate) => rate.cancellation_policy.length > 0)
        );
        setShowCancellationbtn(shouldShowCancelButton);
        setRooms(hoteldata.hotel_details.rooms);
        setHotel(hoteldata);
        setInvoiceDetail(response.data.booking_data);
        // await fetchHotelDetails(
        //   hoteldata.hotel_details.hotel_code,
        //   data.provider
        // );
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const calculateRefund = (bookingData, totalBookingAmount,bookingamount) => {
    const today = new Date();
    let totalRefundAmount = 0; // Start with a full refund
    // Filter for dates that are in the past
    bookingData.rooms.forEach((room) => {
      room.room_rates.forEach((rate) => {
        if (
          rate.cancellation_policy === "" ||
          rate.cancellation_policy.length === 0
        ) {
          totalRefundAmount += 0;
        } else {
          const pastDates = rate.cancellation_policy
            .filter((item) => new Date(item.from_date) < today)
            .sort((a, b) => new Date(b.from_date) - new Date(a.from_date));
          const recentPastDate = pastDates.length > 0 ? pastDates[0] : null;
          if (recentPastDate !== null) {
            if (
              recentPastDate.type === "Amount" ||
              recentPastDate.type === "Fix Amount"
            ) {
              const percent=(Number(recentPastDate.amount)/Number(totalBookingAmount))*100;
              const npercentageAmount =(parseFloat(percent) / 100) * bookingamount;
              totalRefundAmount +=bookingamount  - npercentageAmount;
            } else if (recentPastDate.type === "Percentage") {
              const percentageAmount =(parseFloat(recentPastDate.amount) / 100) * bookingamount;
              totalRefundAmount += bookingamount - percentageAmount;
            }
          } else {
            totalRefundAmount += bookingamount;
          }
        }
      });
    });
    setRefundAmount(totalRefundAmount); // Assuming this sets the calculated refund amount in state
  };

  // const calculateRefund = (bookingData) => {
  //   console.log(bookingData);
  //   const today = new Date();
  //   let totalAmount = 0;
  //   bookingData.rooms.forEach((room) => {
  //     room.room_rates.forEach((rate) => {
  //       rate.cancellation_policy.forEach((policy) => {
  //         const fromDate = new Date(policy.from_date);
  //         if (today < fromDate) {
  //           totalAmount += parseFloat(policy.amount);
  //         }
  //       });
  //     });
  //   });

  //   setRefundAmount(totalAmount);
  // };
  const handleRefundPayment = async () => {
    try {
      const res = await stripe.refunds.create({
        payment_intent: paymentObject.id,
        amount: Number(refundAmount * 100).toFixed(0),
      });
      // console.log(res);
      if (res.status !== "succeeded") {
        console.error(res);
        toast.error("Refund failed. Please contact support.");
        // setRefundResponse({ message: error.message });
      } else {
        toast.success(
          "Payment refunded. It may take a few days for the money to reach the customer bank account."
        );
      }
    } catch (error) {
      console.log(error);
      console.error(error);
      toast.error(error.message);
      // setRefundResponse({ message: 'Error processing refund' });
    } finally {
      // setIsRefunding(false);
    }
  };
  const total = () => {
    let roomsprice = 0;
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = 0;
    var length = rooms.length;
    for (var x = 0; x < length; x++) {
      roomsprice = Number(rooms[x].room_rates[0].net);
      finalpricemarkup = roomsprice;
      if (Number(hotel.admin_markup) !== 0) {
        if (hotel.admin_markup_type === "Percentage") {
          markupprice = (roomsprice * Number(hotel.admin_markup)) / 100;
        } else {
          markupprice = Number(hotel.admin_markup);
        }
        // adminmarkupprice=markupprice;
        finalpricemarkup += markupprice;
      }
      if (Number(hotel.customer_markup) !== 0) {
        if (hotel.customer_markup_type === "Percentage") {
          markupprice =
            (finalpricemarkup * Number(hotel.customer_markup)) / 100;
        } else {
          markupprice = Number(hotel.customer_markup);
        }
        //  clientmarkupprice=markupprice;
        finalpricemarkup += markupprice;
      }
      finalRoomPrices.push(finalpricemarkup);
      setRoomPriceSums(finalRoomPrices);
    }
  };
  const calculatemarkup = (price) => {
    let roomsprice = Number(price);
    let markupprice = 0;
    let finalpricemarkup = Number(price);

    if (Number(hotel.admin_markup) !== 0) {
      if (hotel.admin_markup_type === "Percentage") {
        markupprice = (roomsprice * Number(hotel.admin_markup)) / 100;
      } else {
        markupprice = Number(hotel.admin_markup);
      }
      // adminmarkupprice=markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(hotel.customer_markup) !== 0) {
      if (hotel.customer_markup_type === "Percentage") {
        markupprice = (Number(price) * Number(hotel.customer_markup)) / 100;
      } else {
        markupprice = Number(hotel.customer_markup);
      }
      //  clientmarkupprice=markupprice;
      finalpricemarkup += markupprice;
    }
    return finalpricemarkup.toFixed(2);
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const BookingCancillation = async () => {
    var apitoken = ApiToken();
    setModalOpen(!modalOpen);
    var data = {
      token: apitoken,
      invoice_no: invoiceNo,
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/hotel/reservation/cancell/new_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      var data = JSON.parse(response.data.view_reservation_details);
      if (data.error) {
        setIsLoading(false);
        toast.info(data.error.message);
      } else {
        if (paymentObject.length !== 0 && refundAmount > 0) {
          await handleRefundPayment();
        }
        toast.info("Your reservation has been cancelled");
        getinvoicedetail();
      }
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const fetchHotelDetails = async (hotelId, provider) => {
    var apitoken = ApiToken();
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken,
    };

    try {
      const response = await Axios.post(
        endpoint + "/api/hotels/mata_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setHotelMeta(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div style={{ background: "#80808017" }}>
        <div className="cs-container">
          <div className="cs-invoice cs-style1">
            <div className="cs-invoice_in">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src={logo} height="90" width="70" />
                  <div className="ms-2 invoice-text-theme">
                    <h4 className="text-info fw-bold">Alsubaee Holidays</h4>
                    <p className="fw-bold">For Travels</p>
                  </div>
                </div>
                <div>
                  <h4>Client Voucher</h4>
                  <p>Booking ID: {hotelDetail.reference_no}</p>
                </div>
              </div>
              <div className="p-3 voucher-bg mt-3">
                <div className="row">
                  <h4 className="my-2">
                    Hotel Name: {hoteldetail2.hotel_name} (
                    {hoteldetail2.destinationName})
                  </h4>
                  <div className="col-md-6">
                    <ul class="list-group list-group-borderless">
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Booking Status:
                        </span>
                        {invoiceDetail.status === "Cancelled" || invoiceDetail.status === "CANCELLED" ? (
                          <span class="text-danger fw-normal mb-0">
                            {invoiceDetail.status}
                          </span>
                        ) : (
                          <span class="text-success-voucher fw-normal mb-0">
                            {invoiceDetail.status === "In_Progess"
                              ? "In Progress"
                              : invoiceDetail.status}
                          </span>
                        )}
                      </li>
                      {invoiceDetail.payment_Reject_Status !==null && (
                      <li class=" d-sm-flex text-danger fw-bold justify-content-between align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-vr fa-fw me-2"></i>Payment Status:
                        </span>
                        <span class=" mb-0">
                          {invoiceDetail.payment_Reject_Status}
                        </span>
                      </li>
                      )}
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-wallet2 fa-fw me-2"></i>Invoice No:
                        </span>
                        <span class=" fw-normal mb-0"> {invoiceNo}</span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-wallet2 fa-fw me-2"></i>HCN No:
                        </span>
                        <span class=" fw-normal mb-0"> {hcnNumber}</span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-wallet2 fa-fw me-2"></i>Al Subaee Res No:
                        </span>
                        <span class=" fw-normal mb-0"> {brnNumber}</span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-person fa-fw me-2"></i>Guest Name:
                        </span>
                        <span class=" fw-normal mb-0">
                          {leadPassenger.lead_title +
                            " " +
                            leadPassenger.lead_first_name +
                            " " +
                            leadPassenger.lead_last_name}
                        </span>
                      </li>

                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-currency-dollar fa-fw me-2"></i>Due
                          Amount:
                        </span>
                        <span class=" fw-normal mb-0">
                          {invoiceDetail.selected_currency}{" "}
                          {invoiceDetail.status == "Cancelled"
                            ? "0.0"
                            : invoiceDetail.payment_details != null
                            ? JSON.parse(invoiceDetail.payment_details) === ""
                              ? invoiceDetail.exchange_price
                              : 0
                            : invoiceDetail.exchange_price}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul class="list-group list-group-borderless">
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-calendar fa-fw me-2"></i>Booking Date:
                        </span>
                        <span class=" fw-normal mb-0">
                          {moment(invoiceDetail.creationDate).format("ll")}
                        </span>
                      </li>
                      {CheckoutRes.slc_pyment_method === 'Bank_Payment'&& invoiceDetail.status !== "Confirmed" && (
                          <li style={{fontFamily:'system-ui'}} class="d-sm-flex gap-2 align-items-center">
                            <span class="mb-0 fw-bold">
                              <i class="bi bi-calendar fa-fw me-2"></i>Option Date :
                            </span>
                            {moment(invoiceDetail.creationDate).add(1, 'days').isAfter(moment()) ? (
                              <span class="fw-bold mb-0">
                                {moment(invoiceDetail.creationDate).add(1, 'days').format("ll")}
                              </span>
                            ) : (
                              <span class="text-danger fw-bold mb-0">
                               {moment(invoiceDetail.creationDate).add(1, 'days').format("ll")} (past due)
                              </span>
                            )}
                          </li>
                        )}

                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-calendar fa-fw me-2"></i>Checkin Date:
                        </span>
                        <span class=" fw-normal mb-0">
                          {moment(hoteldetail2.checkIn).format("ll")}
                        </span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-calendar fa-fw me-2"></i>Checkout
                          Date:
                        </span>
                        <span class=" fw-normal mb-0">
                          {moment(hoteldetail2.checkOut).format("ll")}
                        </span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-people fa-fw me-2"></i>Duration:
                        </span>
                        <span class=" fw-normal mb-0">
                          {daysBetween} {Number(daysBetween)===1?'Night':'Nights'}
                        </span>
                      </li>
                      <li class=" d-sm-flex gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-currency-dollar fa-fw me-2"></i>
                          Total Price:
                        </span>
                        <span class=" fw-normal mb-0">
                          {invoiceDetail.selected_currency}{" "}
                          {invoiceDetail.status == "Cancelled"
                            ? invoiceDetail.after_Confirm_Status==='Cancelled'? Number(invoiceDetail.exchange_price):0.0
                            : invoiceDetail.exchange_price}
                        </span>
                      </li>
                      {invoiceDetail.after_Confirm_Status==='Cancelled' && (
                      <li class=" d-sm-flex text-danger fw-bold gap-2 align-items-center">
                        <span class="mb-0">
                          <i class="bi bi-currency-dollar fa-fw me-2"></i>
                          Cancellation Charges:
                        </span>
                        <span class=" mb-0">
                          {invoiceDetail.selected_currency}{" "}
                          {invoiceDetail.cancellation_Price_CR}
                        </span>
                      </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <p className="my-2 fw-bold text-light-color ">
                Dear Sir, We seize this opportunity to thanks you very much for
                your kind interest and confidence in us.
              </p>
              <div className="mt-30">
                <h5 className="p-2 bg-secondary text-light">Room Details</h5>
                <div class="mobile-table-scroll mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Board Type</th>
                        <th>Room Quantity</th>
                        <th>Passenger</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rooms.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {item.room_name}</td>
                          <td>{item.room_code}</td>
                          <td> {item.room_rates[0].room_board}</td>
                          <td>{item.room_rates[0].room_qty}</td>
                          <td>
                            {" "}
                            {item.room_rates[0].adults} {Number(item.room_rates[0].adults)===1?'Adult':'Adults'},{" "}
                            {item.room_rates[0].children} {Number(item.room_rates[0].children)===1?'Children':'Childrens'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {mealList.length !==0 && (<>
                <h5 className="p-2 bg-secondary text-light mt-4">Meal Details</h5>
                <div class="mobile-table-scroll mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Adult</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mealList.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {item.name}</td>
                          <td>{item.quantity}</td>
                          <td>{invoiceDetail.selected_currency} {(Number(item.totalPrice)*Number(CheckoutRes.exchange_rate)).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </>)}
              </div>
              <div className="mt-30 vouvher-terms">
                <h5 className="p-3">Terms & Conditions</h5>
                <div className="p-4 ms-4">
                  <ul style={{ listStyle: "disc" }}>
                    <li>
                      Above Rates are net & non commission-able quoted in Saudi
                      Riyals
                    </li>
                    <li>
                      Check in after 16:00 hours and check out at 12:00 hour
                    </li>
                    <li>
                      Check in or check out amendment for individuals should be
                      done 72 hours prior to guest check in.
                    </li>
                    <li>
                      Check in or check out amendment for Group should be 7 days
                      prior to guest check in.
                    </li>
                    <li>
                      In case of no-show for groups full amount will be charged.
                    </li>
                    <li>
                      In case of no-show for individuals first night will be
                      charged.
                    </li>
                    <li>
                      Triple or Quad occupancy will be through extra bed, If
                      standard room is not available.
                    </li>
                    <li>
                      For Waqf Osman Bin Affan Hotel bookings – check in through
                      ALSUBAEE representative.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex print-div-hide justify-content-end mt-3">
                <div className="me-2">
                  <NavLink to="/">
                    <button class="btn cursor-pointer btn-info mb-0">
                      <FontAwesomeIcon icon={faHome} /> Home
                    </button>
                  </NavLink>
                </div>
                {moment().isBefore(moment(hoteldetail2.checkIn)) && (
                <div className="me-2">
                  {showCancellationbtn && (
                    <button
                      onClick={toggleModal}
                      disabled={invoiceDetail.status === "Cancelled"}
                      class="btn cursor-pointer btn-danger mb-0"
                    >
                      <i class="bi bi-file-pdf me-2"></i>Cancel Booking
                    </button>
                  )}
                </div>
                )}
                <div>
                  <NavLink to={`/hotel_invoice/${invoiceNo}`}>
                    <p class="btn cursor-pointer btn-primary mb-0">
                      <i class="bi bi-file-pdf me-2"></i>View Invoice
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={modalOpen} onClose={toggleModal}>
        <Modal.Header>
          <Modal.Title> Confirmation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={BookingCancillation}
            color="red"
            appearance="primary"
          >
            Confirm
          </Button>
          <Button onClick={toggleModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HotelVoucher;
